import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import GLightBoxLoader from '@uz/unitz-components-web/Glightbox/loader';
// import LoadingScreen from '@uz/unitz-components-web/LoadingScreen';
import { Helmet } from 'react-helmet';

const sectionRender = (section) => {
  return <div>default section</div>;
};

const Index = (props) => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      {ctx.set('dataPage', 'home')}
      <Helmet>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/glightbox/dist/css/glightbox.min.css" />
        <script src="https://appssdk.zoom.us/sdk.min.js"></script>
      </Helmet>
      <GLightBoxLoader />
      {ctx.get('pageData.sections', []).map((section, index) => {
        const content = (
          <React.Fragment key={index}>{ctx.apply('ctf.renderEntry', { name: section.name })}</React.Fragment>
        );
        if (!content) {
          return sectionRender(section);
        }
        return content;
      })}
      {/* <DIV className="isLoading">
        <div className="fixed top-0 w-full h-screen select-none bg-white500" style={{ zIndex: 1000 }}>
          <LoadingScreen />
        </div>
      </DIV> */}
    </DIV>
  );
};

export default displayName(Index);
