import React from 'react';
import GbContentfulProvider from '@uz/unitz-providers/GbContentfulProvider';
import createLayout from '@vl/mod-utils/createLayout';
import { Helmet } from 'react-helmet';

import './base.css';

export const Layout = createLayout(({ children, PageData }) => {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" type="text/css" href="https://unitz-css.web.app/styles.css?v=2023011110" />
      </Helmet>
      <PageData>
        {(ctfData) => {
          return (
            <GbContentfulProvider data={ctfData}>
              <div>
                <div id="layoutcontent">{children}</div>
                <div className="flex flex-col min-h-screen h-fill-screen">
                  <div className="app-header">
                    <Layout.RenderPOS name="app-header" />
                  </div>
                  <div className="flex-1 app-body">
                    <Layout.RenderPOS name="app-body" />
                  </div>
                  <div className="app-footer">
                    <Layout.RenderPOS name="app-footer" />
                  </div>
                </div>
              </div>
            </GbContentfulProvider>
          );
        }}
      </PageData>
    </>
  );
});

export default Layout;
