import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import _ from 'lodash';
import CourseRoomFormatter from '@uz/unitz-models/CourseRoomModel/formatter';
import moment from 'moment';
import { TIMESTAMPTZ_FORMAT } from '@vl/mod-utils/datetime';
import UserModel from '@uz/unitz-models/UserModel';
import useObservableSource from '@vl/hooks/useObservableSource';
import useSubjectSource from '@vl/hooks/useSubjectSource';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadings: hook(() => React.useMemo(() => ({}))),
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: true }))),
            pageData: hook((ctx) => {
              _.debounce(() => {
                ctx.apply('loadingModel.clearLoading', 'fetchProfile');
              }, 1900)();
              const pageData = ctx.apply('ctf.findPage', { name: 'NewHomePageLandingClone' });
              return pageData;
            }),
            courseRoomsToday: hook((ctx) => {
              const ref = React.useRef({});
              _.assign(ref.current, { ctx });
              const userId = ctx.apply('authModel.getUserId');
              const subject = useObservableSource(() =>
                UserModel.find(
                  `where: {id: {_eq: "${userId}"}}`,
                  `course_rooms(
                      order_by: {start_at: asc_nulls_last},
                      where: {
                        start_at: {_gte: "${moment()
                          .startOf('day')
                          .utc()
                          .format(TIMESTAMPTZ_FORMAT)}"
                        }
                        end_at: {_lte: "${moment()
                          .endOf('day')
                          .utc()
                          .format(TIMESTAMPTZ_FORMAT)}"
                        }
                      }
                    ) {
                    id
                  }`
                )
              );
              const users = useSubjectSource(subject);
              const items = _.get(users, '0.course_rooms', []);
              ref.current.courseRooms = CourseRoomFormatter.formatRoomEvents(ctx)(items);

              return {
                items: ref.current.courseRooms || [],
              };
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => ctx.apply('loadingModel.isLoading')),
        },
      ],
    ],
  },
  notLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
        },
      ],
    ],
  },
});

export default bindData;
